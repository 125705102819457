import * as React from "react";
import Table from "react-bootstrap/Table";
import {reportsUrl} from "./index";

class SummaryRow extends React.Component {

    render() {
        return <tr>
          <td>{this.props.task}</td><td>{this.props.time}</td>
        </tr>
    }
}

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {summaryRows:[]}
    }

    componentDidMount() {
        fetch(reportsUrl)
            .then(response => response.json())
            .then(summaryRows => this.setState({summaryRows}));
    }

    refreshSummary() {
        this.componentDidMount();
    }

    render() {
        let sumRows = Array.of();
        for (let i = 0; i < this.state.summaryRows.length; i++) {
          let sumRow = this.state.summaryRows[i];
          let sr = <SummaryRow key={i} task={sumRow.taskName} time={sumRow.time}/>;
          sumRows.push(sr);
        }

        return (
            <div>
                <Table striped bordered hover size="sm" className="w-25 p-3">
                    <tbody>
                    <tr>
                        <th colSpan={"2"}>Task Totals</th>
                    </tr>
                    {sumRows}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default Summary