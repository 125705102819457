import React from "react";
import {entriesUrl} from "./index";
import Datetime from 'react-datetime';
import Table from "react-bootstrap/Table";

class Entry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            entryTime: new Date(Date.parse(this.props.entry.time)),
        };
        this.onChangeTime= this.onChangeTime.bind(this);
        this.saveNewTime= this.saveNewTime.bind(this);
    }

    onChangeTime(event) {
      console.log("onChangeTime. event, state");
      console.log(event);
      console.log(this.state);
      this.setState({
          entryTime : event
      });
    }

    saveNewTime(event) {
      console.log("Updating: " + this.props.entry.entryId);
      fetch(entriesUrl + "/" + this.props.entry.entryId, {
            method: "PUT",
            body: JSON.stringify({
                "entryId" : this.props.entry.entryId,
                "taskId": this.props.entry.taskId,
                "time": this.state.entryTime,
                "personId": "12345"
            })
        })
        .then(() => this.props.refreshEntries());
    }

    render() {
        return (
            <tr>
                <td>
                    {this.props.entry.taskName}
                </td>
                <td>
                  <Datetime value={this.state.entryTime} dateFormat={false} timeFormat={"hh:mm A"} onChange={this.onChangeTime} onBlur={this.saveNewTime}/>
                </td>
            </tr>
        );
    }
}

class Entries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {entries: []};
        this.tasksRef = props.parent;
        this.refreshEntriesList = this.refreshEntriesList.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        fetch(entriesUrl)
            .then(response => response.json())
            .then(pentries => this.setState({entries:pentries}));
    }

    refreshEntriesList() {
        console.log("refreshEntries");
        this.setState({entries:[]});
        this.componentDidMount();
    }

    render() {
        const entriesList = [];
        for (let i = 0; i < this.state.entries.length; i++) {
            let item = <Entry
                key={i}
                entry={this.state.entries[i]}
                parent={this}
                refreshEntries={this.tasksRef.refreshEntries}
            />;
            entriesList.push(item);
        }
        return (
            <div>
                <Table striped bordered hover size="sm" className="w-25 p-3">
                    <tbody>
                    <tr>
                        <th colSpan={"2"}>Entries</th>
                    </tr>
                    {entriesList}
                    </tbody>
                </Table>
            </div>
        );

    }
}

export default Entries