import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Tasks from "./tasks";

const baseUrl = "https://jcxsroh5ya.execute-api.ap-southeast-2.amazonaws.com/staging";
export const tasksUrl =  baseUrl + "/tasks";
export const entriesUrl = baseUrl + "/entries";
export const reportsUrl = baseUrl + "/summary";

// ========================================

ReactDOM.render(
  <Tasks/>,
  document.getElementById('root')
);

