import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Button from 'react-bootstrap/Button';
import TaskItem from "./taskitem";
import {tasksUrl} from "./index";
import {forEach} from "react-bootstrap/cjs/ElementChildren";
import Entries from "./entries";
import Summary from "./summary";

class Tasks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {tasks: [], newTaskName: "", tasksStr: ""};

        this.entriesList = React.createRef();
        this.summary = React.createRef();

        this.handleNewTaskChange = this.handleNewTaskChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.refreshEntries = this.refreshEntries.bind(this);
        this.reReadList = this.reReadList.bind(this);
    }

    printEntries() {
        forEach(this.entries, e => console.log(e));
    }

    refreshEntries() {
        this.entriesList.current.refreshEntriesList();
        this.summary.current.refreshSummary();
    }

    componentDidMount() {
        fetch(tasksUrl)
            .then(response => response.json())
            .then(tasks => this.setState({tasks}));
    }

    reReadList() {
      this.state.tasks.sort(function(t1, t2) {
        if (t1.TaskName > t2.TaskName) {
          return 1;
        }
        if (t1.TaskName < t2.TaskName) {
          return -1;
        }
        return 0;
      });
      this.componentDidMount();
      this.setState({newTaskName: ""});
//      this.refreshEntries();
    }

    handleNewTaskChange(event) {
        this.setState({newTaskName: event.target.value});
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //   console.log("tasks: comp did update: prevState: [" + prevState.entries + " : " + prevState.tasks + "]");
    //   // if (!this.state.tasks) {
    //   //     this.setState({tasks:[], entries:this.state.entries});
    //   // }
    //   // console.log("comp did update. tasks: " + this.state.tasks);
    // }

    handleSubmit(event) {
        event.preventDefault();
        console.log("New Task: " + this.state.newTaskName);
        const task = {
            taskName: this.state.newTaskName
        };
        fetch(tasksUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(task)
        })
            .then(resp => resp.json())
            .then(t => this.state.tasks.push(t))
            .then(() => this.setState({newTaskName: ""}));
    }

    render() {
        const taskItemList = Array.of();
        for (let i = 0; i < this.state.tasks.length; i++) {
            let item = <TaskItem
                key={i}
                task={this.state.tasks[i]}
                parent={this}
                refreshEntries={this.refreshEntries}
            />;
            taskItemList.push(item);
        }
        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                  <h2>Current Tasks</h2>
                    <table className="taskList">
                        <thead>
                        <tr>
                            <th>Select Task</th>
                            <th>Rename</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {taskItemList}
                        </tbody>
                    </table>
                    <br/>
                    <label>New Task:<br/>
                        <input type="text" value={this.state.newTaskName} onChange={this.handleNewTaskChange}/>
                    </label>
                    <Button type="submit" value="Submit" variant="primary">Submit</Button>
                </form>
                <Summary ref={this.summary}/>
                <Entries ref={this.entriesList} parent={this}/>
            </div>
        );
    }
}

export default Tasks
