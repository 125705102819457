import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import {entriesUrl, tasksUrl} from "./index";

class TaskItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      task: props.task,
      taskId: props.task.taskId
    };

    this.handleSaveAndCloseModal = this.handleSaveAndCloseModal.bind(this);
    this.handleCancelAndCloseModal = this.handleCancelAndCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.onDeleteTask = this.onDeleteTask.bind(this);
    this.onModifyTask = this.onModifyTask.bind(this);
    this.onSelectTask = this.onSelectTask.bind(this);
  }

  onSelectTask(event) {
    console.log("Selected: " + event.target.value);
    fetch(entriesUrl, {
      method: "POST",
      body: JSON.stringify({"taskId": event.target.value,
      "time": new Date().toISOString(),
      "personId":"23456"})
    })
    .then(() => this.props.refreshEntries());
  }

  onDeleteTask(event) {
    console.log("To Delete: " + event.target.value);
    fetch(tasksUrl + '/' + event.target.value, {
      method: "DELETE"
    })
      .then(() => this.props.parent.reReadList())
//      .then(() => this.props.reReadList())
//      .then(() => this.setState({state: this.state}))  // to trigger a re-render
    ;
  }

  onModifyTask(event) {
    this.setState({
      task:{
        taskName: event.target.value,
        taskId: this.state.task.taskId
      }
    });
    console.log("modify task; event: " + event.target.value + ", state: " + this.state.task.taskName);
  }

  handleSaveAndCloseModal (event) {
    console.log("onSaveAndClose, event:" + event.target.value + ", state: " + this.state.task.taskName);
    // save the task
    console.log("PUT - url is: " + tasksUrl);
    console.log("body is: " + JSON.stringify(this.state.task));
    fetch(tasksUrl + "/" + this.state.task.taskId, {
      method: 'PUT',
      body: JSON.stringify(this.state.task),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      // .then(response => response.json())
      // .then(data => console.log("after PUT: " + data))
      .then(() => this.props.parent.refreshEntries())
    ;
    this.setState({ showModal: false });
  }

  handleCancelAndCloseModal (event) {
    console.log("onClose, event:" + event.target.value + ", state: " + this.state.task.taskName);
    this.setState({ showModal: false });
  }

  handleOpenModal (event) {
    console.log("onOpen, event:" + event.target.value);
    this.setState({ showModal: true });
  }

  render() {
    return (
      <tr>
        <td>
          <Button variant="success" onClick={this.onSelectTask} value={this.state.task.taskId}>{this.state.task.taskName}</Button>
        </td>
        <td id={this.state.taskId}>
          <Button variant="primary" onClick={this.handleOpenModal} value={this.state.taskId}>R</Button>
        </td>
        <td>
          <Button variant="danger" onClick={this.onDeleteTask} value={this.state.task.taskId}>D</Button>
        </td>
        <td>
          <Modal
            isOpen={this.state.showModal}
            contentLabel="Modify Task"
            appElement={document.getElementById(this.state.taskId)}
          >
            <input type="text" value={this.state.task.taskName} onChange={this.onModifyTask} />
            <Button onClick={this.handleSaveAndCloseModal} value={this.state.task.taskId}>Save</Button>
            &nbsp;
            <Button onClick={this.handleCancelAndCloseModal} value={this.state.task.taskId}>Cancel</Button>
          </Modal>
        </td>
      </tr>
    );
  }
}

export default TaskItem

